<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <PERQuestionCategorySelect
              v-model="filters.category_id"
              all-option="All"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <LevelsSelect
              v-model="filters.level_id"
              all-option="All"
            />
          </div>

          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="newQuestion"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Question</md-tooltip>
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass: 'table',
              }"
              @onShowDetails="onShowDetails"
              @onDelete="onDelete"
              @changed="getQuestions"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <questions-form
      v-if="showForm"
      :item="selQuestion"
      @close="closeForm"
      @onSave="initTable"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { LevelsSelect, PERQuestionCategorySelect } from '@/components/Inputs/selects';
import QuestionsForm from './QuestionsForm.vue';

export default {
  components: {
    VueTable,
    QuestionsForm,
    LevelsSelect,
    PERQuestionCategorySelect,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'level.name',
            mask: 'level',
            sortable: true,
          },
          {
            title: 'category.title',
            mask: 'category',
            sortable: true,
          },
          {
            title: 'title',
            mask: 'question',
            sortable: true,
            max_chars: 100,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Question',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      filters: {
        per_page: 25,
        level_id: 'all',
        category_id: 'all',
        search: '',
        sortBy: '',
        sortDir: '',
      },
      showForm: false,
      selQuestion: null,
    };
  },
  watch: {
    'filters.level_id': {
      handler(level_id) {
        if (level_id === null) {
          this.filters.level_id = 'all';
        }
      },
    },
  },
  mounted() {
    this.initTable();
  },
  methods: {
    getQuestions(params) {
      if (params) {
        for (const [key, value] of Object.entries(params)) {
          this.filters[key] = value;
        }
      }

      this.request('per_questions', 'get', {
        ...this.filters,
      }, ({ data }) => {
        this.vTable.values = data;
      });
    },
    onShowDetails(item) {
      this.selQuestion = { ...item };
      this.showForm = true;
    },
    onDelete(item) {
      this.request(`per_questions/${item.per_question_id}`, 'delete', null, () => {
        this.initTable();
        this.fireSuccess('Question deleted');
      });
    },
    onFilter() {
      this.getQuestions();
    },
    initTable() {
      this.$refs.vtable.init();
    },
    newQuestion() {
      this.showForm = true;
    },
    closeForm() {
      this.showForm = false;
      this.selQuestion = null;
    },
  },
};
</script>
