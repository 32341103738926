<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="close"
  >
    <template slot="title">
      Question Form
    </template>
    <template slot="body">
      <div class="md-layout">
        <div class="md-layout-item">
          <FormText
            v-model="form.title"
            label="Question Title*"
            icon="description"
          />

          <LevelsSelect
            v-model="form.level_id"
            label="Level*"
            rules="required"
          />

          <PERQuestionCategorySelect
            v-model="form.category_id"
          />
        </div>
      </div>
    </template>
    <template slot="footer">
      <md-button
        class="md-primary pull-right"
        @click="saveForm"
      >
        <md-icon class="mr-2">
          save
        </md-icon>
        Save
      </md-button>
    </template>
  </vue-modal>
</template>

<script>
import {
  FormText,
} from '@/components/Inputs';
import { LevelsSelect, PERQuestionCategorySelect } from '@/components/Inputs/selects';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', required);

export default {
  components: {
    FormText,
    LevelsSelect,
    PERQuestionCategorySelect,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        title: null,
        level_id: null,
        category_id: null,
      },
      categories: [],
    };
  },
  mounted() {
    if (this.item) this.form = this.item;
  },
  methods: {
    close() {
      this.$emit('close');
    },
    saveForm() {
      const errors = [];
      // Validation
      if (!this.form.title) {
        errors.push('Question Title attribute is required');
      }
      if (!this.form.level_id) {
        errors.push('Level attribute is required');
      }
      if (!this.form.category_id) {
        errors.push('Category attribute is required');
      }

      if (errors.length) {
        errors.forEach((x) => {
          this.fireError(x);
        });
      } else if (this.form.per_question_id) {
        this.request(`per_questions/${this.form.per_question_id}`, 'put', this.form, () => {
          this.fireSuccess('Question updated succesfully!');
          this.$emit('onSave');
          this.$refs.modal.close();
        });
      } else {
        this.request('per_questions', 'post', this.form, () => {
          this.fireSuccess('Question created succesfully!');
          this.$emit('onSave');
          this.$refs.modal.close();
        });
      }
    },
    cleanForm() {
      this.form = {
        title: null,
        level_id: null,
        category_id: null,
      };
    },
  },
};
</script>
<style></style>
